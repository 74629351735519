










import { Component, Vue, Prop } from "vue-property-decorator";
import CommonPatient from "./common-patient.vue";
@Component({
  components: {
    CommonPatient,
  },
})
export default class Name extends Vue {
  @Prop()
  private ifShow: any;
  @Prop({
    type: Boolean,
    default: true,
  })
  private ifSave: any; // 点击保存按钮时是否保存，false时不保存把数据传回父组件
  private get configuration() {
    return this.$store.state.configuration;
  }
  private cancel() {
    this.$emit("cancel", false);
  }
  private change(e: any) {
    this.$emit("change", e);
  }
}
