import CryptoJS from 'crypto-js';

export default {
    //随机生成指定数量的16进制key
    // generatekey(num: any) {
    //     let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    //     let key = "";
    //     for (var i = 0; i < num; i++) {
    //         let randomPoz = Math.floor(Math.random() * library.length);
    //         key += library.substring(randomPoz, randomPoz + 1);
    //     }
    //     return key;
    // },

    //加密
    encrypt(word: any, keyStr?: any, IV?: any) {
        keyStr = keyStr ? keyStr : 'asdufbhy3456dfl9'; //判断是否存在ksy，不存在就用定义好的key
        IV = IV ? IV : 'rv614fyvuc7w4db2';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    },
    //解密
    decrypt(word: any, keyStr?: any, IV?: any) {
        keyStr = keyStr ? keyStr : 'asdufbhy3456dfl9';
        IV = IV ? IV : 'rv614fyvuc7w4db2';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var iv = CryptoJS.enc.Utf8.parse(IV);
        var decrypt = CryptoJS.AES.decrypt(word, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }

}