import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 获取量表疾病选项
 */
const PostJibing = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/jws_diseases", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 上传文件
 */
const UploadFile = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/file", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 术语搜素（西医疾病、中医疾病、治法治则、中医证型、中医症状）
 * @param vCom Vue组件
 */
const GetTerms = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/terms", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 上传图片
 * @param vCom Vue组件
 */
const UploadImage = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/image", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 上传视频
 * @param vCom Vue组件
 */
const UploadVideo = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/upload/file", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目录式控件的选项
 * @param vCom Vue组件
 */
const GetMulu = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/mulu").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 问题弹框
 */
const PostQuestion = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/each/question", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 题目记录
 */
const PostQuestionRecords = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/question/records", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取当前用户信息，如果传了project_id则是获取当前专病的用户信息
 * @param vCom Vue组件
 */
const GetCurrentUserData = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/user", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
export {
    PostJibing, UploadFile, GetTerms, UploadImage, UploadVideo, GetMulu,
    PostQuestion, PostQuestionRecords, GetCurrentUserData
}

