




















import { Component, Vue, Prop } from "vue-property-decorator";
import AgreeCommon from "../agree/agree-common.vue";
import AgreeShuguang from "../agree/agree-shuguang.vue";
@Component({
  components: {
    AgreeCommon,
    AgreeShuguang,
  },
})
export default class Name extends Vue {
  @Prop()
  private ifShow: any;
  private get configuration() {
    return this.$store.state.configuration;
  }
  private cancel() {
    this.$emit("cancel", false);
  }
}
